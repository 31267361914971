function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

const embtContainer = document.getElementById("embdt-container");
const ETElement = document.createElement("div");

if (!inIframe()) {
  const header = `
    <header style="padding:10px 0;background:#3d5a80; color:#fff; position:fixed; top:0; left:0; width:100%; display:grid;place-items:center;">
      <a href="https://embedtables.com">
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 100 100"><rect width="100" height="100" rx="20" fill="#293241"></rect><path fill="#fff" d="M19.63 76.86L19.63 76.86Q16.75 76.86 15.08 75.15Q13.42 73.44 13.42 70.56L13.42 70.56L13.42 28.26Q13.42 25.38 15.08 23.72Q16.75 22.05 19.63 22.05L19.63 22.05L49.42 22.05Q49.87 22.77 50.23 23.94Q50.59 25.11 50.59 26.55L50.59 26.55Q50.59 28.89 49.51 30.15Q48.43 31.41 46.54 31.41L46.54 31.41L24.76 31.41L24.76 44.19L46.72 44.19Q47.17 44.91 47.57 46.04Q47.98 47.16 47.98 48.60L47.98 48.60Q47.98 53.46 43.84 53.46L43.84 53.46L24.76 53.46L24.76 67.41L49.78 67.41Q50.23 68.13 50.63 69.35Q51.04 70.56 51.04 72.00L51.04 72.00Q51.04 76.86 46.90 76.86L46.90 76.86L19.63 76.86ZM72.19 63.45L72.19 63.45Q72.19 66.33 73.94 67.59Q75.70 68.85 78.84 68.85L78.84 68.85Q80.38 68.85 81.95 68.49Q83.53 68.13 84.79 67.59L84.79 67.59Q85.51 68.31 86 69.35Q86.50 70.38 86.50 71.73L86.50 71.73Q86.50 74.43 84.07 76.19Q81.64 77.94 76.24 77.94L76.24 77.94Q69.13 77.94 65.03 74.75Q60.94 71.55 60.94 64.17L60.94 64.17L60.94 22.86Q61.66 22.68 63.05 22.41Q64.45 22.14 66.07 22.14L66.07 22.14Q69.22 22.14 70.70 23.31Q72.19 24.48 72.19 27.45L72.19 27.45L72.19 34.92L85.42 34.92Q85.87 35.64 86.23 36.81Q86.59 37.98 86.59 39.33L86.59 39.33Q86.59 41.67 85.51 42.75Q84.43 43.83 82.63 43.83L82.63 43.83L72.19 43.83L72.19 63.45Z"></path></svg>
      </a>

    </header>
  `;
  const headerDOM = document.createElement("header");
  headerDOM.innerHTML = header;
  document.body.appendChild(headerDOM);
  document.body.style.padding = "8% 10%";
}

document.body.style.fontFamily = "Arial, Helvetica, sans-serif";

const projectId = window.self.location.pathname.split("/")[1];

if (projectId) {
  ETElement.dataset["embedtableid"] = projectId;
  embtContainer.appendChild(ETElement);
} else {
  window.location.replace("https://embedtables.com");
}
